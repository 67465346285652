import { useEffect, useState } from "react";
import NextLink from "next/link";
import Box from "@mui/material/Box";
import Text from "@ds/components/Text";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MenuItem from "@mui/material/MenuItem";
import useStyles from "./styles";
import NavbarDropdown from "../../components/NavbarDropdown";
import type { TSolutionProps, TNavData, TCategoryProps } from "./type";

const MenuSolutionBusiness: React.FC<TSolutionProps> = ({ data, loading = false, isOnPage, t }) => {
  const [selected, setSelected] = useState<string>("");
  const [categories, setCategories] = useState<TCategoryProps[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles({ categories });

  useEffect(() => {
    setSelected(data[0]?.type || "");
    setCategories(data[0]?.category || []);
  }, [data]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    const selectedProducts = data.find((item: TNavData) => item.type === newValue)?.category;
    setSelected(newValue);
    setCategories(selectedProducts as TCategoryProps[]);
  };

  const onClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <NavbarDropdown
      id="business-solution"
      title={t("menus.solution")}
      loading={loading}
      anchorEl={anchorEl}
      onClick={onClickMenu}
      onClose={onCloseMenu}
      isOnPage={isOnPage}
      withCategory
    >
      <Box className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selected}
          scrollButtons={false}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {data.map((item: TNavData, idx: number) => {
            let navLabel;
            switch (item.type) {
              case "Small Medium Enterprise":
                navLabel = t("menus.category_solution.0");
                break;
              case "Enterprise":
                navLabel = t("menus.category_solution.1");
                break;
              case "Government":
                navLabel = t("menus.category_solution.2");
                break;
            }
            return (
              <Tab className={classes.tabItem} key={idx} label={navLabel} value={item.type} />
            );
          })}
        </Tabs>
        <Box className={classes.menuItemWrapper}>
          {categories?.length > 0 &&
            categories.map((item: TCategoryProps, idx) => {
              let parentUrl = "";
              switch (selected) {
                case "Small Medium Enterprise":
                  parentUrl = "small-medium-enterprise";
                  break;
                case "Enterprise":
                  parentUrl = "enterprise";
                  break;
                case "Government":
                  parentUrl = "government";
                  break;
              }
              return (
                <MenuItem
                  classes={{
                    root: classes.list,
                  }}
                  onClick={onCloseMenu}
                  key={idx}
                >
                  <NextLink
                    href={{
                      pathname: `/${parentUrl}/[category]`,
                      query: { category: item.slug },
                    }}
                    passHref
                  >
                    <a className={classes.anchor}>
                      <img
                        src={item.categoryIcon?.mediaPath}
                        alt={item.categoryIcon?.mediaName}
                        className={classes.categoryIcon}
                      />
                      <Text variant="h4" color="general-dark">
                        {item.categoryName}
                      </Text>
                    </a>
                  </NextLink>
                </MenuItem>
              );
            })}
        </Box>
      </Box>
    </NavbarDropdown>
  );
};

export default MenuSolutionBusiness;
