import { useState } from "react";
import NextLink from "next/link";
import Box from "@mui/material/Box";
import Text from "@ds/components/Text";
import MenuItem from "@mui/material/MenuItem";
import NavbarDropdown from "../../components/NavbarDropdown";
import type { TProductProps } from "./type";
import useStyles from "./styles";

const MenuProduct: React.FC<TProductProps> = ({ data = [], loading = false, isOnPage, t }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles({ data });

  const onClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <NavbarDropdown
      id="product-services"
      title={t("menus.product")}
      loading={loading}
      anchorEl={anchorEl}
      onClick={onClickMenu}
      onClose={onCloseMenu}
      isOnPage={isOnPage}
    >
      <Box className={classes.menuItemWrapper}>
        {data?.map((item, idx) => {
          const slug = item.productSlug;
          return (
            <MenuItem
              classes={{
                root: classes.list,
              }}
              onClick={onCloseMenu}
              key={idx}
            >
              <NextLink href={`/${slug}`} passHref>
                <a className={classes.anchor}>
                  <img
                    src={item.productIcon?.mediaPath}
                    alt={item.productIcon?.mediaName}
                    className={classes.productIcon}
                  />
                  <Text variant="h4" color="general-dark" css={{ marginLeft: 16 }}>
                    {item.productName}
                  </Text>
                </a>
              </NextLink>
            </MenuItem>
          );
        })}
      </Box>
    </NavbarDropdown>
  );
};

export default MenuProduct;
