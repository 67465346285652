import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: "rgba(46, 67, 77, 0.5)",
  },
  paper: {
    marginTop: 16,
    borderRadius: 16,
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.05)"
  },
  menu: {
    padding: `28px 24px`,
    minWidth: 280,
  },
}));

export default useStyles;