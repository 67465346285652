import { makeStyles } from "@mui/styles";
import color from "@definitions/mui/color";
import { TCategoryProps } from "./type";

const useStyles = ({ categories }: { categories: TCategoryProps[] }) => {
  return makeStyles(() => ({
    anchor: {
      display: "inline-flex",
      textDecoration: "none",
      width: "100%",
      alignItems: "center",
      gap: 10,
    },
    root: {
      display: "flex",
      gap: 16,
    },
    tabItem: {
      textAlign: "start",
      display: "flex",
      alignItems: "flex-start",
      fontSize: "16px",
      fontWeight: 600,
    },
    tabSelected: {
      color: color.primary.main,
    },
    tabHover: {
      "&:hover": {
        color: color.primary.main,
      },
    },
    labelTabWrapper: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      gap: 18,
    },
    labelTab: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      "&:hover": {
        color: color.primary.main,
      },
    },
    categoryIcon: {
      width: 28,
      height: 28,
      objectFit: "cover",
    },
    menuItemWrapper: {
      display: "flex",
      flexWrap: "wrap",
      width: 700,
    },
    list: {
      display: "flex",
      alignItems: "flex-start",
      margin: `6px 0`,
      width: categories.length > 6 ? "50%" : "100%",
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      borderRadius: 8,
      "& > img": {
        marginRight: 16,
      },
      "&:hover": {
        backgroundColor: color.grey.mid,
      },
    },
  }))();
};

export default useStyles;
