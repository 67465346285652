import Link from "next/link";
import { useRouter } from "next/router";
import Box from "@mui/material/Box";
import Text from "@ds/components/Text";
import MenuBusinessSolution from "../../lib/MenuSolutionBusiness";
import MenuProduct from "../../lib/MenuProduct";
import type { TNavbarMenuProps } from "./type";

const NavbarMenu: React.FC<TNavbarMenuProps> = (props) => {
  const { dataNavbar, loadNavbar, businessSolution, t } = props;
  const router = useRouter();
  return (
    <Box display="flex" alignItems="center">
      <MenuProduct
        data={dataNavbar}
        loading={loadNavbar}
        t={t}
        isOnPage={router.pathname.includes("level")}
      />
      <MenuBusinessSolution
        data={businessSolution}
        loading={loadNavbar}
        t={t}
        isOnPage={
          (router.pathname.includes("category") && !router.pathname.includes("insight")) ||
          router.pathname.includes("government")
        }
      />
      <Link href="/tentang-kami" passHref>
        <a>
          <Box px="15px">
            <Text
              variant="body1"
              weight="700"
              color={router.asPath.includes("tentang-kami") ? "primary-main" : "general-main"}
            >
              {t("menus.about")}
            </Text>
          </Box>
        </a>
      </Link>
      <Link href="/insight" passHref>
        <a>
          <Box px="15px">
            <Text
              variant="body1"
              weight="700"
              color={router.asPath.includes("insight") ? "primary-main" : "general-main"}
            >
              {t("menus.insight")}
            </Text>
          </Box>
        </a>
      </Link>
      <Link href="/bantuan" passHref>
        <a>
          <Box px="15px">
            <Text
              variant="body1"
              weight="700"
              color={router.asPath.includes("bantuan") ? "primary-main" : "general-main"}
            >
              {t("menus.support")}
            </Text>
          </Box>
        </a>
      </Link>
    </Box>
  );
};

export default NavbarMenu;
