import { useTranslation } from "next-i18next";
import { PopoverOrigin } from "@mui/material";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Button from "@ds/components/Button";
import Text from "@ds/components/Text";
import NavSkeleton from "./component/NavSkeleton";
import useStyles from "./styles";
import type { TNavbarProps } from "./type";

const NavbarDropdown: React.FC<TNavbarProps> = ({
  id,
  children,
  title = "",
  loading = false,
  anchorEl,
  onClick,
  onClose,
  withCategory,
  isOnPage,
}) => {
  const open = Boolean(anchorEl);
  const { t } = useTranslation("header");
  const classes = useStyles();

  const _renderMenuNavbar = () => {
    if (loading) return <NavSkeleton />;
    return children;
  };

  const setAnchorOrigin: PopoverOrigin = withCategory
    ? { horizontal: "center", vertical: "bottom" }
    : { horizontal: "left", vertical: "bottom" };

  const setTransformOrigin: PopoverOrigin = withCategory
    ? { horizontal: "center", vertical: "top" }
    : { horizontal: "left", vertical: "top" };

  return (
    <>
      <Button
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        onClick={onClick}
        css={{
          paddingLeft: `10px !important`,
          paddingRight: `4px !important`,
        }}
      >
        <Text
          variant="body1"
          weight="700"
          sx={{ display: "flex" }}
          align="left"
          color={
            (isOnPage && title === t("menus.product")) ||
              (isOnPage && title === t("menus.solution"))
              ? "primary-main"
              : "general-main"
          }
        >
          {title}
        </Text>
        <KeyboardArrowDownRoundedIcon />
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          classes: {
            root: classes.menu,
          },
        }}
        PopoverClasses={{
          paper: classes.paper,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0 0px 3px rgba(0, 0, 0, 0.2))",
            mt: 1.5,
            "&:before": {
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 16px 16px 16px",
              borderColor: "transparent transparent #fff transparent",
              content: '""',
              position: "absolute",
              top: -16,
              left: withCategory ? 505 : 142,
            },
          },
        }}
        transformOrigin={setTransformOrigin}
        anchorOrigin={setAnchorOrigin}
        TransitionComponent={Fade}
      >
        <ClickAwayListener onClickAway={onClose}>
          <>{_renderMenuNavbar()}</>
        </ClickAwayListener>
      </Menu>
    </>
  );
};

export default NavbarDropdown;
