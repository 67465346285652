import { makeStyles } from "@mui/styles";
import color from "@definitions/mui/color";

import { TProductIconData } from "./type";

const useStyles = ({ data }: { data: TProductIconData[] }) => makeStyles(() => ({
  list: {
    margin: `6px 0`,
    whiteSpace: "normal",
    width: data.length > 6 ? "50%" : "100%",
    borderRadius: 8,
    "& > img": {
      marginRight: 16
    },
    "&:hover": {
      backgroundColor: color.grey.mid,
    }
  },
  anchor: {
    display: "inline-flex",
    textDecoration: "none",
    width: "100%"
  },
  menuItemWrapper: {
    display: "flex",
    width: 880,
    flexFlow: "wrap",
    flexGrow: 1,
  },
  productIcon: {
    width: 28,
    height: 28,
    objectFit: "cover",
  },
}))();

export default useStyles;