import { Grid } from "@mui/material";
import Skeleton from "@ds/components/Skeleton";

const NavSkeleton: React.FC = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Skeleton width={"100%"} height={34} sx={{ marginBottom: 10 }} />
        <Skeleton width={"100%"} height={34} sx={{ marginBottom: 10 }} />
        <Skeleton width={"100%"} height={34} sx={{ marginBottom: 10 }} />
        <Skeleton width={"100%"} height={34} sx={{ marginBottom: 10 }} />
        <Skeleton width={"100%"} height={34} sx={{ marginBottom: 10 }} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton width={"100%"} height={34} sx={{ marginBottom: 10 }} />
        <Skeleton width={"100%"} height={34} sx={{ marginBottom: 10 }} />
        <Skeleton width={"100%"} height={34} sx={{ marginBottom: 10 }} />
        <Skeleton width={"100%"} height={34} sx={{ marginBottom: 10 }} />
      </Grid>
    </Grid>
  );
};

export default NavSkeleton;